import React from "react";
import sessionBut from "../../Images/session-button.png";
import now from "../../Images/now-clock.png";
import other from "../../Images/other-clock.png";
import {useGetClientSessionsQuery} from "@/App/services/clientApiSlice";
import moment from "moment";
import Loader from "@/Components/loader";
import empty from "../../Images/noWorksheet.png";
import {useNavigate} from "react-router-dom";

function Upcoming({id}) {
    const navigate = useNavigate();

    const {data: upcoming, isSuccess, isLoading} = useGetClientSessionsQuery({
        id: id,
        status: "Upcoming",
    });
    console.log(upcoming);

    return (
        <div className="space-y-4">
            {isLoading ? (
                <Loader/>
            ) : !upcoming?.data?.length ? (
                <div className="w-full flex-col my-auto laptop:min-h-[292px] flex justify-center items-center">
                    <img src={empty} className="w-[108px]" alt="empty"/>
                    <p className="mt-3 text-sm text-[#868C98] text-center">
                        No Upcoming Appointments. <br/>
                    </p>
                </div>
            ) : (
                isSuccess &&
                upcoming?.data?.map((data) => {

                    const NewstartTime = moment(data?.starts_at).subtract(5, "minutes").format();
                    const endTime = moment(NewstartTime).add(data?.duration || 0 + 15, "minutes").format();
                    const isTimeNow = moment().isSameOrAfter(NewstartTime) && moment().isSameOrBefore(endTime);

                    return (
                        <div key={data.id} className="pt-4 px-2 pb-2 space-y-3 border border-[#E2E4E9] rounded-[12px]">
                            <div className="px-2 flex flex-col justify-start items-start">
                                <div className="flex justify-between w-full items-center">
                                    <h4 className="font-medium capitalize text-[14px] leading-5 text-[#0A0D14]">
                                        {data.user?.name || "Unknown"} {/* Fallback for missing user */}
                                    </h4>
                                    <div className="flex justify-center items-center gap-[6px]">
                                        <p className="text-[12px] leading-4 font-medium text-[#693D11] bg-[#FBDFB1] rounded-[50px] px-2 py-[2px]">
                                            {data.status}
                                        </p>
                                        <img className="w-[24px]" src={sessionBut} alt="view"/>
                                    </div>
                                </div>
                                <p className="text-[#525866] text-[12px] font-normal leading-4 pt-[2px]">
                                    {moment(data?.starts_at).format("LT")}
                                </p>
                            </div>
                            <div
                                className={`${
                                    data.when === "Now" ? "bg-[#CBF5E5]" : "bg-[#F6F8FA]"
                                } p-2 rounded-[8px] flex justify-between items-center`}
                            >
                                <div className="flex justify-center items-center gap-1">
                                    <img
                                        src={data.when === "Now" ? now : other}
                                        className="w-4"
                                        alt="clock"
                                    />
                                    <p className="text-[12px] leading-4 text-[#525866]">
                                        {moment(data?.starts_at).endOf("").fromNow()}
                                    </p>
                                </div>
                                {isTimeNow && data.status !== "Cancelled" ? (
                                    <p
                                        onClick={() => navigate(`/video-chat/${data?.id}`, {state: data})}
                                        className="underline font-medium text-[12px] leading-4 text-[#176448] cursor-pointer"
                                    >
                                        Start Call
                                    </p>
                                ) : (
                                    <p className="text-[#525866] text-[12px] leading-4">
                                        {moment(data?.starts_at).format("ddd, MMMM Do YYYY")}
                                    </p>
                                )}
                            </div>
                        </div>
                    );
                })
            )}
        </div>
    );
}

export default Upcoming;
