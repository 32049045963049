import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/Components/ui/dialog";
import { useState } from "react";
import calendar from "../../../Images/calendar.png";
import pencil from "../../../Images/pencil.png";
import alarm from "../../../Images/alarm.png";
import time from "../../../Images/time.png";
import { useCancelSessionMutation, useGetSessionDetailsQuery, useGetSessionNoteQuery } from "@/App/services/sessionSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import Loader, { FormLoader } from "@/Components/loader";
import moment from "moment";
import Reschedule from "./Reschedule";
import * as Icon from 'react-feather'
import { handleError } from "@/App/utils/renderErrors";


const noteKeys = [ 
  'assessment_interventions', 'assessment_progress', 'assessment_observations', 
  'subjective_history', 'subjective_psychological', 'subjective_relevant', 
  'objective_appearance', 'objective_behavior', 'objective_symptoms', 'objective_risk', 'summary' 
]

function SessionInfo({ openModal, setModal, sessionDetails, sessionId, status='Completed' }) {

  const navigate = useNavigate()
  const [readFull, setRead] = useState(false);
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);

  const { data:session, isFetching } = useGetSessionDetailsQuery((sessionDetails?.id || sessionId) ?? skipToken);
  const { data:sessionNote } = useGetSessionNoteQuery((sessionDetails?.id || sessionId) ?? skipToken)
  const [ cancelSession, { isLoading:cancelLoading } ] = useCancelSessionMutation();

  let isTimeNow;
  const NewstartTime = moment(session?.data?.starts_at).subtract(5, 'minutes').format()
  const endTime = moment(NewstartTime).add(session?.data?.duration + 15, 'minutes').format();

  if(moment().isSameOrAfter(NewstartTime) && moment().isSameOrBefore(endTime) ){
    isTimeNow = true
  };

  const handleCancelSession = async() => {
    try {
      const reason = {
        reason: "Rescheduling..."
      }

      const res = await cancelSession({ id: sessionDetails.id, reason }).unwrap()
      toast.success(res.message);
      setModal(false)
    } catch(err){
      const errorMessage = handleError(err);
      toast.error(errorMessage);
    }
  }

  return (
    <div>
      <Reschedule
        openRescheduleModal={openRescheduleModal}
        setRescheduleModal={setOpenRescheduleModal}
        sessionId={sessionDetails?.id}
        session={session}
      />
      <Dialog open={openModal} onOpenChange={setModal} >
        <DialogContent
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
          className="w-[90vw] overflow-y-scroll  max-h-[80vh] max-w-[566px] py-6 duo:py-8 px-6 duo:pl-9 duo:pr-8 border-none rounded-[16px]"
        >
          <DialogHeader>
            <DialogTitle className="text-left text-[#0A0D14]">
              Session Details
            </DialogTitle>
          </DialogHeader>
          <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mt-1" />
          {
            isFetching ?
            <Loader />
            :
            <div className="">
              <div className="flex flex-col justify-center items-center">
                <img src={session?.data?.user?.avatar} alt="avatar" className="w-[85px]" />
                <h2 className="text-2xl font-bold capitalize pt-3 text-[#0A0D14]">
                  {session?.data?.user?.name ?? '--- ---'}
                </h2>
                {/* <p className="text-sm pt-1 text-[#525866]">8 previous sessions</p> */}
                <div className="flex justify-center items-center gap-3 mt-4">
                  <button onClick={() => { navigate(`/dashboard/client_list/${session?.data?.user?.id}`); setModal((prev) => !prev) }} className="rounded-[50px] border border-[#E2E4E9] text-[#525866] text-sm  py-[10px] font-semibold px-4">
                    View Profile
                  </button>
                  <button className="rounded-[50px] border border-[#E2E4E9] text-[#525866] text-sm  py-[10px] font-semibold px-4">
                    Send Message
                  </button>
                </div>
              </div>
              <div className="bg-[#F6F8FA] w-full rounded-[8px] py-[5px] px-4 mt-2 space-y-3 ">
                <div>
                  <h4 className="text-xs text-[#525866]">Date</h4>
                  <div className="flex justify-start items-start gap-[6px] mt-2">
                    <img className="w-5" src={calendar} alt="date" />
                    <h2 className="text-sm text-[#0A0D14]">
                     {moment(session?.data?.starts_at).format("dddd,  MMMM Do YYYY")}
                    </h2>
                  </div>
                </div>
                <div>
                  <h4 className="text-xs text-[#525866]">Time</h4>
                  <div className="flex justify-start items-start gap-[6px] mt-2">
                    <img className="w-5" src={time} alt="time" />
                    <h2 className="text-sm text-[#0A0D14]">{moment(session?.data?.starts_at).format("LT")}</h2>
                  </div>
                </div>
                <div>
                  <h4 className="text-xs text-[#525866]">Duration</h4>
                  <div className="flex justify-start items-start gap-[6px] mt-2">
                    <img className="w-5" src={alarm} alt="duration" />
                    <h2 className="text-sm text-[#0A0D14]">{session?.data?.duration} mins</h2>
                  </div>
                </div>
                <div>
                  <h4 className="text-xs text-[#525866]">Additional Note</h4>
                  <div className="flex justify-start items-start gap-[6px] mt-2">
                    <img className="w-5" src={pencil} alt="date" />
                    <h2 className="text-sm text-[#0A0D14]">
                     {(session?.data?.note && session?.data?.note) ??  '- - -'}
                    </h2>
                  </div>
                </div>
                <div>
                  <h4 className="text-xs text-[#525866]">Session Focus</h4>
                  <div className="flex justify-start items-start gap-[6px] mt-2">
                    <h2 className="text-sm text-[#0A0D14]">
                      <div className="flex flex-col gap-2">
                        {(session?.data?.focus && session?.data?.focus.map((item) => (
                          <div className="flex items-center gap-2">
                            <Icon.Circle size={3} fill="#000000" />
                            <span className="text-xs text-[#0A0D14]">{item}</span>
                          </div>
                        ))) ??  '- - -'}
                      </div>
                    </h2>
                  </div>
                </div>
              </div>

                <div className={` ${ session?.data?.status === 'Completed' ? 'block' : 'hidden'  } `}>
                  <h3 className="text-[#0A0D14] mt-4 text-sm font-medium">
                    Session Note
                  </h3>
                  <section className="flex flex-col gap-3">
                    <header className="text-lg font-semibold">Summary</header>
                    <p className="text-sm text-[#0A0D14]">
                      {sessionNote?.data?.summary ?? 'N/A' }
                    </p>
                  </section>
                  {!readFull && (
                    <button
                      onClick={() => setRead(true)}
                      className="text-sm font-medium mt-3 underline text-[#1A6A73]"
                    >
                      Read Full Note
                    </button>
                  )}
                  {readFull && (
                    <div className="w-full mt-4 space-y-4">
                      <div className="space-y-6">
                  
                        <section className="flex flex-col gap-3">
                          <header className="text-lg font-semibold">Assessment</header>
                          <ul className="flex flex-col gap-3">
                            <li className="flex flex-col gap-1">
                              <span className="text-sm text-[#0A0D14]">Interventions</span>
                              <span className="text-sm text-[#0A0D14]">{sessionNote?.data?.assessment_interventions ?? 'N/A'}</span>
                            </li>
                            <li className="flex flex-col gap-1">
                              <span className="text-sm text-[#0A0D14]">Progress</span>
                              <span className="text-sm text-[#0A0D14]">{sessionNote?.data?.assessment_progress ?? 'N/A'}</span>
                            </li>
                            <li className="flex flex-col gap-1">
                              <span className="text-sm text-[#0A0D14]">Observations</span>
                              <span className="text-sm text-[#0A0D14]">{sessionNote?.data?.assessment_observations ?? 'N/A'}</span>
                            </li>
                          </ul>
                        </section>
                        <section className="flex flex-col gap-3">
                          <header className="text-lg font-semibold">Subjective</header>
                          <ul className="flex flex-col gap-3">
                            <li className="flex flex-col gap-1">
                              <span className="text-sm text-[#0A0D14]">Social History</span>
                              <span className="text-sm text-[#0A0D14]">{sessionNote?.data?.[noteKeys[3]] ?? 'N/A'}</span>
                            </li>
                            <li className="flex flex-col gap-1">
                              <span className="text-sm text-[#0A0D14]">Psychological</span>
                              <span className="text-sm text-[#0A0D14]">{sessionNote?.data?.[noteKeys[4]] ?? 'N/A'}</span>
                            </li>
                            <li className="flex flex-col gap-1">
                              <span className="text-sm text-[#0A0D14]">Relevant History</span>
                              <span className="text-sm text-[#0A0D14]">{sessionNote?.data?.[noteKeys[5]] ?? 'N/A'}</span>
                            </li>
                          </ul>
                        </section>
                        <section className="flex flex-col gap-3">
                          <header className="text-lg font-semibold">Objective</header>
                          <ul className="flex flex-col gap-3">
                            <li className="flex flex-col gap-1">
                              <span className="text-sm text-[#0A0D14]">Appearance</span>
                              <span className="text-sm text-[#0A0D14]">{sessionNote?.data?.[noteKeys[6]] ?? 'N/A'}</span>
                            </li>
                            <li className="flex flex-col gap-1">
                              <span className="text-sm text-[#0A0D14]">Behaviour</span>
                              <span className="text-sm text-[#0A0D14]">{sessionNote?.data?.[noteKeys[7]] ?? 'N/A'}</span>
                            </li>
                            <li className="flex flex-col gap-1">
                              <span className="text-sm text-[#0A0D14]">Symptoms</span>
                              <span className="text-sm text-[#0A0D14]">{sessionNote?.data?.[noteKeys[8]] ?? 'N/A'}</span>
                            </li>
                            <li className="flex flex-col gap-1">
                              <span className="text-sm text-[#0A0D14]">Risk Assessment</span>
                              <span className="text-sm text-[#0A0D14]">{sessionNote?.data?.[noteKeys[9]] ?? 'N/A'}</span>
                            </li>
                          </ul>
                        </section>
                        </div>
                              <button
                                onClick={() => setRead(false)}
                                className="text-sm font-medium underline text-[#1A6A73]"
                              >
                                Read Summary
                              </button>
                            </div>
                          )}
                        </div>

                      <div className={`${ (session?.data?.status === 'Upcoming' || session?.data?.status === 'Confirmed') ? 'block' : 'hidden' }`}>
                        <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mt-2 mb-5" />
                        <div className="*:text-sm *:font-medium flex justify-between items-center">
                          <button
                            disabled={cancelLoading}
                            onClick={handleCancelSession} className={`text-[#DF1C41] border-none outline-none underline`}>
                            { cancelLoading ? <FormLoader /> : "Cancel Session" }
                          </button>
                          <div className="flex justify-center items-center gap-2">
                            <button onClick={() => {setOpenRescheduleModal(prev => !prev); setModal(false)}} className="text-white bg-[#20232D] rounded-[50px] py-[10px] px-4 ">
                              Reschedule
                            </button>
                              <button onClick={() => navigate(`/video-chat/${session?.data?.id}`, { state: sessionDetails } )} className={`text-white bg-[#1A6A73] rounded-[50px] py-[10px] px-4 cursor-pointer ${ isTimeNow ? 'block' : 'hidden' } `}>
                                Start Call
                              </button>
                          </div>
                        </div>
                      </div>
                    </div>
                      }
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SessionInfo;
