import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/Components/ui/dialog";
import alarm from "../../../Images/alarm.png";
import { useState, useEffect } from "react";
import timeImg from "../../../Images/time.png";
import calendar2 from "../../../Images/calendar2.png";
import { Label } from "@/Components/ui/label";
import { format } from "date-fns";
// import { Calendar as CalendarIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/Components/ui/button";
import { Calendar } from "@/Components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/Components/ui/popover";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/Components/ui/select";


import { useCreateSessionMutation, useGetSessionnFocusListQuery } from "@/App/services/sessionSlice";
import { toast } from "sonner";
// import moment from "moment";
import moment from "moment-timezone";
import { useGetBlockedDatesQuery } from "@/App/services/appointmentsApiSlice";
import MultiSelect from "@/Components/ui/mutliselect";
import { FormLoader } from "@/Components/loader";
import { handleError } from "@/App/utils/renderErrors";
// import { toast } from "react-toastify";

const yesterdaysDate = moment().subtract(1, "days").format();

function ClientNewSession({ openModal, setModal, setSessionsPage, client }) {
  const [date, setDate] = useState();
  const [time, setTime] = useState("");
  const [note, setNote] = useState("");
  const [ampm, setAmPm] = useState("AM");
  const [duration, setDuration] = useState("45");
  const [selectedItems, setSelectedItems] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const userTimezone = moment.tz.guess();

  // useEffect(() => {
  //   setTimeSlots(generateTimeSlots(userTimezone));
  // }, [userTimezone]);
  
  useEffect(() => {
    if (date) {
      const filteredSlots = filterTimeSlotsBasedOnDate(date);
      setTimeSlots(filteredSlots);
    }
  }, [date]);

  
  const { data:blockedDates } = useGetBlockedDatesQuery()
  const [ createSession, { isLoading } ] = useCreateSessionMutation();
  const { data:focusList, isLoading:focusListLoading } = useGetSessionnFocusListQuery();
  const transformedFocusList = focusList && focusList?.data?.map((focus) => {
    return focus?.name
  })

  const blocked = blockedDates && blockedDates?.data?.map((date) => {
    return {
      from: date?.from_date,
      to: date?.to_date,
    }
  })

  const disabledDays = blocked ? [
    { from: new Date(2000, 0, 1), to: yesterdaysDate },
     ...blocked
  ] : null;

  function handleEdit() {
    setSessionsPage(1);
  }

  const generateTimeSlots = (selectedDate) => {
    const slots = [];
    const now = moment(); // Current time
    const isToday = selectedDate.isSame(now, 'day'); // Check if selected date is today
    console.log("isToday", isToday, now)
    for (let i = 0; i < 24; i++) {
      const hour = (i % 12) || 12; // Convert 0 to 12 for 12-hour format
      const period = i < 12 || i === 24 ? 'AM' : 'PM'; // Determine AM or PM
      const formattedHour = hour < 10 ? `0${hour}` : hour; // Format hour with leading zero if needed
      
      const timeSlot = `${formattedHour}:00 ${period}`;
      
      // For today, only include slots from the current time onwards
      if (isToday) {
        if (i > now.hour() || (i === now.hour() && now.minute() === 0)) {
          slots.push(timeSlot);
        }
      } else {
        // For future dates, include all slots
        slots.push(timeSlot);
        console.log("timeSlot", timeSlot)
      }
    }
    
    return slots;
  };
  
  

  const filterTimeSlotsBasedOnDate = (selectedDate) => {
    const filteredSlots = [];
    const now = moment().tz(moment.tz.guess()); // Current date and time
    const selected = moment(selectedDate).tz(moment.tz.guess()); // Selected date
    const slots = generateTimeSlots(selected); // Generate all possible slots

    
    // Check if the selected date is today or a future date
    const isToday = now.isSame(selected, 'day');
  
    if (isToday) {
      // If selected date is today, filter slots based on current time
      const currentHour = now.hour();
      for (const slot of slots) {
        const [hour, period] = slot.split(' ');
        const hourInt = parseInt(hour, 10);
        const isPM = period === 'PM';
        
        // Convert 12-hour format to 24-hour format
        const hour24 = isPM && hourInt !== 12 ? hourInt + 12 : !isPM && hourInt === 12 ? 0 : hourInt;
  
        // Add slots that are later than current time
        if (hour24 > currentHour || (hour24 === currentHour && now.minute() === 0)) {
          filteredSlots.push(slot);
        }
      }
    } else {
      // If selected date is not today, allow all slots within the range
        const startHour = 0; // Start of the time slot range (1 AM)
        const endHour = 24; // End of the time slot range (12 AM, next day)
        
        for (const slot of slots) {
          const [hour, period] = slot.split(' ');
          const hourInt = parseInt(hour, 10);
          const isPM = period === 'PM';
          
          // Convert 12-hour format to 24-hour format
          const hour24 = (hourInt % 12) + (isPM ? 12 : 0);

          // Add slots within the allowed range
          if (hour24 >= startHour && hour24 < endHour) {
            filteredSlots.push(slot);
          }
        }
      }
  
    return filteredSlots;
  };
  

  const handleCreateSession = async () => {
    const userTimezone = moment.tz.guess(); // Get user's timezone
    const selectedDateTime = moment.tz(`${moment(date).format("YYYY-MM-DD")} ${time}`, "YYYY-MM-DD hh:mm A", userTimezone);
    const currentDateTime = moment.tz(userTimezone);
  
    // Check if selected time is in the past
    if (selectedDateTime.isBefore(currentDateTime)) {
      return toast.error("You can't select a passed time");
    }
  
    try {
      const sessionDetails = {
        user_id: client?.id.toString(),
        date: selectedDateTime.format('YYYY-MM-DD'),
        time: selectedDateTime.format('hh:mm A'), // Format in 12-hour clock with AM/PM
        duration: duration,
        focus: [...selectedItems],
        note: note
      };
  
      const res = await createSession({ ...sessionDetails }).unwrap();
      toast.success("Session has been successfully created");
    } catch (err) {
      const errorMessage = handleError(err);
      toast.error(errorMessage);
    }
  
    setSessionsPage(() => 1);
    setModal(false);
  };
  

  return (
    <Dialog open={openModal} onOpenChange={setModal}>
      <DialogContent
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        className="w-[90vw] overflow-y-scroll min-h-[ max-h-[85vh] max-w-[566px] py-6 duo:py-8 px-6 duo:pl-9 duo:pr-8 border-none rounded-[16px]"
      >
        <DialogHeader>
          <DialogTitle className="text-left text-[#0A0D14]">
            Start a New Session
          </DialogTitle>
          <DialogDescription className="text-[#525866] text-left">
            Enter details for the session. Choose the date and time.
          </DialogDescription>
        </DialogHeader>
        <hr className="w-full border-none h-[1px] bg-[#E2E4E9]" />
        <div className="flex justify-between items-center">
          <div className="flex justify-center gap-2 items-center">
            <img style={{ backgroundColor: `${ client?.avatar_background }` }} src={client?.avatar} alt="avatar" className="w-[58px]" />
            <div>
              <h2 className="text-lg leading-6 font-bold capitalize text-[#0A0D14]">
                {client?.name}
              </h2>
              <p className="text-sm  text-[#525866]">8 previous sessions</p>
            </div>
          </div>
          <button
            onClick={handleEdit}
            className="bg-transparent border border-[#E2E4E9] font-meduim text-[#1A6A73] text-sm rounded-[50px] py-2 px-5 "
          >
            Change
          </button>
        </div>
        <div className="space-y-4 mt-4">
          <div className="flex flex-col justify-start items-start gap-2">
            <Label
              htmlFor="name"
              className="text-left font-semibold text-[#0A0D14]"
            >
              Session Date
            </Label>

            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={"outline"}
                  className={cn(
                    "w-full justify-start text-left font-normal",
                    !date && "text-muted-foreground"
                  )}
                  style={{
                    outline: "none",
                    border: "1.5px solid #E2E4E9",
                    boxShadow: "none",
                  }}
                >
                  <img
                    className="w-5 ml-[-4px] mr-[9px]"
                    src={calendar2}
                    alt="time"
                  />
                  {date ? (
                    format(date, "PPP")
                  ) : (
                    <span className="text-[#525866]">Pick a date</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0">
                <Calendar
                  mode="single"
                  selected={date}
                  onSelect={setDate}
                  initialFocus
                  disabled={disabledDays}
                  className='disabled:cursor-pointer'
                />
              </PopoverContent>
            </Popover>
          </div>
          <div className="flex flex-col justify-start items-start gap-2">
            <Label
              htmlFor="name"
              className="text-left font-semibold text-[#0A0D14]"
            >
              Session Time
            </Label>
            <div className="flex w-full">
              <Select value={time} onValueChange={(value) => setTime(value)}>
                <SelectTrigger className="text-[#525866] w-full rounded-[6px] border-[#E2E4E9] focus:ring-offset-0 focus:ring-0">
                  <div className="flex justify-start gap-[7px] ml-[1px] items-center">
                    <img className="w-5" src={timeImg} alt="time" />
                    <SelectValue placeholder="Select a time" />
                  </div>
                </SelectTrigger>
                <SelectContent className="max-h-[165px]">
                  {timeSlots.map((slot, index) => (
                    <SelectItem key={index} value={slot}>
                      {slot}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start gap-2">
            <Label
              htmlFor="name"
              className="text-left font-semibold text-[#0A0D14]"
            >
              Session Duration
            </Label>

            <div className=" w-full">
              <Select
                value={duration}
                onValueChange={(value) => setDuration(value)}
              >
                <SelectTrigger className=" text-[#525866] w-full rounded-[6px]  border-[#E2E4E9] focus:ring-offset-0 focus:ring-0">
                  <div className="flex justify-start gap-[7px] ml-[1px] items-center">
                    <img className="w-5" src={alarm} alt="time" />
                    <SelectValue placeholder="Select the duration" />
                  </div>
                </SelectTrigger>
                <SelectContent className="">
                  <SelectItem value="45">45 mins</SelectItem>
                  {/* <SelectItem value="60">1 hour</SelectItem>
                  <SelectItem value="90">1:30 mins</SelectItem>
                  <SelectItem value="120">2 hours</SelectItem>
                  <SelectItem value="180">3 hours</SelectItem>
                  <SelectItem value="240">4 hours</SelectItem>
                  <SelectItem value="300">5 hours</SelectItem>
                  <SelectItem value="360">6 hours</SelectItem>
                  <SelectItem value="420">7 hours</SelectItem>
                  <SelectItem value="480">8 hours</SelectItem>
                  <SelectItem value="540">9 hours</SelectItem>
                  <SelectItem value="600">10 hours</SelectItem> */}
                  {/* <SelectItem value="more">More than 4 hours</SelectItem> */}
                </SelectContent>
              </Select>
            </div>
          </div>
          <div>
            <Label>
              Session Focus
            </Label>

            <div className="w-full">
              <MultiSelect
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                options={transformedFocusList}
              />
            </div>
          </div>
          <div className="flex flex-col justify-start items-start gap-2">
            <Label
              htmlFor="name"
              className="text-left font-semibold text-[#0A0D14]"
            >
              Additional Note
            </Label>

            <div className=" w-full">
              <textarea
                style={{
                  outline: "none",
                  border: "1.5px solid #E2E4E9",
                  boxShadow: "none",
                }}
                className="w-full text-[#525866] text-sm  rounded-[10px] p-3"
                placeholder="Add other note"
                onChange={(event) => setNote(event.target.value)}
                rows={3}
              />
            </div>
          </div>
          <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mt-2 mb-2" />
          <div className="w-full flex justify-end items-center">
            <button
              onClick={handleCreateSession}
              disabled={isLoading}
              className="text-white font-meduim bg-[#1A6A73] text-sm rounded-[50px] py-[10px] px-4 "
            >
              {isLoading ? <FormLoader /> : 'Create Session'}
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ClientNewSession;
