import React from "react"
import { useEffect, useState } from "react";
import ClientSearch from "./ClientSearch";
import ClientNewSession from "./ClientNewSession";
import { useGetClientListQuery } from "@/App/services/clientApiSlice";

function NewSessionWrapper({openModal, setModal, data = false}) {
  const [sessionPage, setSessioPage] = useState(data ? 2 : 1);
  const [search, setSearch] = useState('');
  const [selectedClient, setSelectedClient] = useState('')

  const { data: clients, isFetching } = useGetClientListQuery({
    search: search
  });
 
  useEffect(() => {
    if (!openModal && !data) {
      setSessioPage(1);
    }
  }, [openModal]);

  return (
    <div>
      {sessionPage === 1 && 
        <ClientSearch
          clients={clients}
          setSearch={setSearch}
          isLoading={isFetching}
          openModal={openModal}
          setModal={setModal}
          setSessionsPage={setSessioPage}
          setSelectedClient={setSelectedClient}
        />
      }
      {sessionPage === 2 && 
        <ClientNewSession 
          openModal={openModal} 
          setModal={setModal} 
          setSessionsPage={setSessioPage}
          client={selectedClient}
        />
      }
    </div>
  );
}

export default NewSessionWrapper;
