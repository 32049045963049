import {apiSlice} from "../api/apiSlice"

export const sessionApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllSessions: builder.query({
            query: () => ({
                url: `/therapist/session/get-sessions`,
                method: "get",
            }),
            providesTags: ['sessions']
        }),
        createSession: builder.mutation({
            query: details => ({
                url: `/therapist/session/create-session`,
                method: "post",
                body: {...details}
            }),
            invalidatesTags: ['sessions']
        }),
        getSessionDetails: builder.query({
            query: id => ({
                url: `/therapist/session/show/${id}`,
                method: "get",
            }),
            providesTags: ['details']
        }),
        cancelSession: builder.mutation({
            query: ({id, reason}) => ({
                url: `/therapist/session/cancel-session/${id}`,
                method: "post",
                body: {...reason}
            }),
            invalidatesTags: ['sessions', 'details'],
        }),
        endSession: builder.mutation({
            query: ({id, reason}) => ({
                url: `/therapist/session/end-session/${id}`,
                method: "post",
                body: {...reason}
            }),
            invalidatesTags: ['sessions', 'details'],
        }),

        rescheduleSession: builder.mutation({
            query: ({id, update}) => ({
                url: `/therapist/session/reschedule-session/${id}`,
                method: "post",
                body: {...update}
            }),
            invalidatesTags: ['sessions', 'details'],
        }),
        createSessionNote: builder.mutation({
            query: sessionNote => ({
                url: `/therapist/session/create-session-note`,
                method: 'post',
                body: {...sessionNote}
            }),
            invalidatesTags: ["notes"]
        }),
        getSessionNote: builder.query({
            query: id => ({
                url: `/therapist/session/show-note/${id}`,
                method: 'get'
            }),
            providesTags: ["notes"]
        }),
        getAllSessionNotes: builder.query({
            query: id => ({
                url: `/therapist/session/all-session-notes?client_id=${id}`,
                method: 'get'
            })
        }),
        getSessionnFocusList: builder.query({
            query: () => ({
                url: `user/session/focus/list`,
                method: 'get'
            })
        }),
    })
})

export const {
    useCreateSessionMutation,
    useGetAllSessionsQuery,
    useGetSessionDetailsQuery,
    useCancelSessionMutation,
    useEndSessionMutation,
    useRescheduleSessionMutation,
    useCreateSessionNoteMutation,
    useGetSessionNoteQuery,
    useGetSessionnFocusListQuery,
    useGetAllSessionNotesQuery,
} = sessionApiSlice