import axios from "axios";
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import "@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css";
import Loader from "@/Components/loader";
import {useEndSessionMutation} from "@/App/services/sessionSlice.js";
import './custom.css';


function VideoScreen() {
    const sessionContainerRef = useRef(null);
    const [currentTime, setCurrentTime] = useState('');
    const [sessionData, setSessionData] = useState(null);
    const [sessionJoined, setSessionJoined] = useState(false);
    const [endSession, {isLoading: endLoading}] = useEndSessionMutation();
    const navigate = useNavigate();
    const {id} = useParams();
    const baseUrl = import.meta.env.VITE_BASE_API_URL;

    // Fetch session data by ID
    useEffect(() => {
        const fetchSession = async () => {
            try {
                const response = await axios.get(`${baseUrl}/session/?id=${id}`);
                setSessionData(response.data);
            } catch (error) {
                console.error('Error fetching session:', error);
            }
        };
        if (id) fetchSession();
    }, [id, baseUrl]);

    // Update time every second
    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            setCurrentTime(now.toLocaleTimeString());
        };
        const intervalId = setInterval(updateTime, 1000);
        return () => clearInterval(intervalId);
    }, []);


    // Function to handle ending the session
    const handleEndSession = async (sessionId, reason) => {
        try {
            const result = await endSession({id: sessionId, reason: {reason}}).unwrap();
            console.log("Session ended successfully:", result);
            navigate("/dashboard/home");

        } catch (error) {
            console.error("Failed to end session:", error);
        }
    };

    const authEndpoint = `${baseUrl}/zoom-sdk-auth`;
    const config = {
        videoSDKJWT: "",
        sessionName: sessionData?.data?.reference || "",
        userName: "yourmentra@gmail.com",
        sessionPasscode: "",
        features: ["video", "audio", "settings", "users", "chat"],
        options: {init: {}, audio: {}, video: {}, share: {}},
        virtualBackground: {
            allowVirtualBackground: true,
            allowVirtualBackgroundUpload: true,
            virtualBackgrounds: [
                "https://images.unsplash.com/photo-1715490187538-30a365fa05bd?q=80&w=1945&auto=format&fit=crop",
            ],
        },
    };
    const role = 1; // 1 for host, 2 for participant

    // Fetch Video SDK JWT and join session
    const getVideoSDKJWT = () => {
        if (!sessionData || sessionJoined || !sessionContainerRef.current) return;

        fetch(authEndpoint, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({sessionName: config.sessionName, role}),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.signature) {
                    console.log(data.signature);
                    config.videoSDKJWT = data.signature;
                    joinSession();
                } else {
                    console.log(data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };


    // Change the view to gallery after the Dom is fully loaded.
    uitoolkit.onSessionJoined(() => {
        setTimeout(() => {
            const changeViewButton = document.querySelector('[aria-label="Change view"]');
            if (changeViewButton) {
                changeViewButton.click();

                const galleryViewButton = [...document.querySelectorAll("span")].find((span) =>
                    span.textContent?.includes("Gallery")
                );

                if (galleryViewButton) {
                    galleryViewButton.click();
                    console.log("View switched to Gallery.");
                } else {
                    console.warn("Gallery view option not found.");
                }
            } else {
                console.warn("Change view button not found.");
            }
        }, 1000);
    });

    // Event listener: Handle session closed
    const sessionClosed = () => {
        console.log("Session closed");
        uitoolkit.closeSession(sessionContainerRef.current);

        const joinFlowElement = document.getElementById("join-flow");
        if (joinFlowElement) {
            joinFlowElement.style.display = "block";
        } else {
            console.warn("'join-flow' element not found in the DOM.");
        }

        handleEndSession(id, "Session ended by the host").then(r => {
            console.log("Session ended successfully:", r);
        });
    };

    // Join the session using UI Toolkit
    const joinSession = () => {
        if (sessionJoined || !config.videoSDKJWT || !sessionContainerRef.current)
            return;

        uitoolkit.joinSession(sessionContainerRef.current, config);
        setSessionJoined(true);

        uitoolkit.onSessionJoined(() => console.log('Session joined'));

        uitoolkit.onSessionClosed(() => {
            console.log('Session closed');
            handleEndSession(id, 'Session ended by the host').then(r => {
                console.log("Session ended successfully:", r);
            });
            setSessionJoined(false);
        });
    };

    // Trigger JWT fetch and join when sessionData is available
    useEffect(() => {
        if (sessionData) {
            getVideoSDKJWT();
        }
        return () => {
            uitoolkit.offSessionJoined(() => setSessionJoined(false));
            uitoolkit.offSessionClosed(() => setSessionJoined(false));
        };
    }, [sessionData]);

    return (
        <div className="video-screen">
            {endLoading && (
                <div className="loader-container">
                    <Loader/>
                </div>
            )}

            <main>
                <header className="header flex justify-between items-center h-16 text-white px-4 md:px-12">
                    <p className="cursor-pointer text-xl font-semibold">Mentra</p>
                    <p className="bg-slate-700 rounded-lg p-2">{currentTime}</p>
                </header>

                <section className={`session`}>
                    <div ref={sessionContainerRef} id="sessionContainer"></div>
                </section>
            </main>
        </div>
    );
}

export default VideoScreen;