import "../../dashboard.css";
import { motion } from "framer-motion";
import sessionBut from "../../Images/session-button.png";
import now from "../../Images/now-clock.png";
import other from "../../Images/other-clock.png";
import cancelses from "../../Images/cancel.png";
import passimg from "../../Images/pass.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@/App/services/authSlice";
import moment from "moment";
import SessionInfo from "../modals/Sessions/SessionInfo";

function ListView({ filteredData, month, year }) {

  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);

  const [openModal, setModal] = useState(false);
  const [sessionDetails, setSessionDetails] = useState(null);

  function handleSessionModal(value) {

    setSessionDetails(() => value);
    setModal(!openModal);
  }
  const variants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeOut",
        duration: 0.5,
      },
    },
  };

  return (
    <motion.div
      key="listView"
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
      className=" w-full"
    >
      {/* <SessionsWraper
        openModal={openModal}
        setModal={setModal}
        status={modalStatus}
      /> */}
      <SessionInfo
          openModal={openModal}
          setModal={setModal}
          sessionDetails={sessionDetails}
      />

      {(() => {
        // const dateString = "Tue Apr 30 2024";

        const calData = filteredData && filteredData?.filter((item) => {
          const dateObject = new Date(item.date);
          const monthNumber = dateObject.getMonth();
          const yearNumber = dateObject.getFullYear();
          const sessionId = item.id

          return monthNumber === month && yearNumber === year;
        });

        return (
          <div className="w-full space-y-3">
            {
              calData?.length > 0 ?
                calData?.map((data, index) => {

                  const NewstartTime = moment(data?.startTime).subtract(5, 'minutes').format()
                  const endTime = moment(NewstartTime).add(data?.duration + 15, 'minutes').format();
                  let isTimeNow;

                  if(moment().isSameOrAfter(NewstartTime) && moment().isSameOrBefore(endTime) ){
                    isTimeNow = true
                  }

                  return (
                    <div
                      key={index}
                      onClick={() => handleSessionModal(data)}
                      className="pt-4 px-2 cursor-pointer bg-white pb-2 space-y-3 border border-[#E2E4E9] rounded-[12px]"
                    >
                      <div className="px-2 flex flex-col justify-start items-start">
                        <div className="flex justify-between w-full items-center">
                          <h4 className="font-medium capitalize text-[14px] leading-5 text-[#0A0D14]">
                            {data.name}
                          </h4>
                          <div className="flex justify-center items-center gap-[6px] ">
                            <p className="text-[12px] leading-4 font-medium text-[#693D11] bg-[#0000000e] rounded-[50px] px-2 py-[2px]">
                              {data.status === "Not_Started" ? 'Not Started' : data.status === "No_Show" ? "No Show" : data.status }
                            </p>
                            <img className="w-[24px]" src={sessionBut} alt="view" />
                          </div>
                        </div>
                        <p className="text-[#525866] text-[12px] font-normal leading-4 pt-[2px]">
                          {data.time}
                        </p>
                      </div>

                      <div
                        className={`${
                          (isTimeNow && data.status !== 'Cancelled')? "bg-[#CBF5E5]" : data.status === "Completed" ? "bg-[#F6F8FA] text-[#525866]"
                           : 
                           data.status === "Upcoming" || data.status === "Confirmed" ? "bg-[#F6F8FA] text-[#525866]"
                            : 
                            data.status === "Cancelled" ? "bg-[#ffcac2f6] text-[#6E330C]"
                            :
                            data.status === 'Awaiting_Approval' ? "bg-[#FBDFB1] text-[#6E330C]"
                            : "bg-[#0000002b] text-[#5654539d]"
                        } p-2 rounded-[8px] flex justify-between items-center`}
                      >
                        <div className="flex justify-center items-center gap-1">
                          <img
                            src={
                              isTimeNow && data.status !== 'Cancelled' ? now
                                : data.status === "Cancelled" || data.status === "no show"
                                ? cancelses
                                : data.status === "Completed"
                                ? passimg
                                : other
                            }
                            className="w-4"
                            alt="clock"
                          />
                          <p className={` ${ data.status === 'Cancelled' ? 'block' : 'hidden'  } text-[12px] leading-4 text-[#0A0D14] `}>
                            Cancelled
                          </p>
                          <p className={` ${ data.status === 'Upcoming' || data.status === 'Confirmed' ? 'block' : 'hidden'  } text-[12px] leading-4 text-[#0A0D14] `}>
                            {(moment(data?.startTime).endOf('').fromNow())}
                          </p>
                          <p className={` ${ data.status === 'Completed' ? 'block' : 'hidden'  } text-[12px] leading-4 text-[#0A0D14] `}>
                            Completed
                          </p>
                          <p className={` ${ data.status === 'Awaiting_Approval' ? 'block' : 'hidden'  } text-[12px] leading-4 text-[#0A0D14] `}>
                            Awaiting_Approval
                          </p>
                          <p className={` ${ data.status === 'Not_Started' ? 'block' : 'hidden'  } text-[12px] leading-4 text-[#0A0D14] `}>
                            Not Started
                          </p>
                          <p className={` ${ data.status === 'No_Show' ? 'block' : 'hidden'  } text-[12px] leading-4 text-[#0A0D14] `}>
                            No Show
                          </p>
                        </div>

                        {isTimeNow && data.status !== 'Cancelled' ? (
                          <p onClick={() => navigate(`/video-chat/${data?.id}`, { state: data } )} className="underline font-medium text-[12px] leading-4 text-[#176448] cursor-pointer">
                            Start Call
                          </p>
                        ) : (
                          <p className="text-[#525866] text-[12px] leading-4">
                            {data.date}
                          </p>
                        )}
                      </div>
                    </div>
                    
                    )
                  })
                    : 
                    (
                      <h2 className="text-xl text-[#0A0D14] font-semibold text-center">
                        No sessions were found{" "}
                      </h2>
                    )
                  }
            </div>
          );
        })()}
    </motion.div>
  );
}

export default ListView;
