import React from 'react'
import searchIcon from "../Images/search.png";
// import filter from "../Images/filter.png";
// import sort from "../Images/sort.png";
// import {
//   Select,
//   SelectContent,
//   SelectGroup,
//   SelectItem,
//   SelectTrigger,
//   SelectValue,
// } from "@/Components/ui/select";

import { useEffect, useMemo, useState } from "react";
import ClientTable from "../components/ClientList/ClientTable";
import PaginationComponent from "../components/ClientList/PaginationComponent";
import { useGetClientListQuery } from "../services/clientApiSlice";
import Loader from "@/Components/loader";

function ClientList() {
  const [search, setSearch] = useState("");
  const [sortCol, setSortCol] = useState("");
  const [asc, setAsc] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const { data: clients, isLoading } = useGetClientListQuery({ search });
  const clientData = clients?.data ?? [];

  const totalPages = Math.ceil(clientData.length / itemsPerPage);

  useEffect(() => {
    setCurrentPage(1); // Reset to first page on search or sort
  }, [search, sortCol, asc]);

  const sortedData = useMemo(() => {
    let sortedClients = [...clientData]; // Create a shallow copy of clients

    if (sortCol) {
      sortedClients.sort((a, b) => {
        const aValue = a[sortCol];
        const bValue = b[sortCol];

        if (sortCol.toLowerCase() === "last" || sortCol.toLowerCase() === "next") {
          const dateA = aValue ? new Date(aValue) : null;
          const dateB = bValue ? new Date(bValue) : null;
          if (!dateA || !dateB) return !dateA ? 1 : -1;
          return asc ? dateA - dateB : dateB - dateA;
        } else if (sortCol.toLowerCase() === "age") {
          return asc ? aValue - bValue : bValue - aValue;
        } else {
          return asc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
      });
    }

    return sortedClients;
  }, [clientData, sortCol, asc]);

  const currentPageData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return sortedData.slice(startIndex, startIndex + itemsPerPage);
  }, [sortedData, currentPage, itemsPerPage]);

  function handleTableSort(value) {
    if (value === sortCol) {
      setAsc(!asc);
    } else {
      setSortCol(value);
      setAsc(true);
    }
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  return (
      <div>
        <div>
          <h2 className="text-[#101828] text-left text-[1.4rem] leading-[30px] tablet:text-[1.9rem] tablet:leading-[38px] font-semibold">
            Client List
          </h2>
          <p className="font-normal text-[14px] leading-[22px] tablet:text-[1rem] text-left tablet:leading-6 max-w-[450px] laptop:max-w-full text-[#475467] pt-[3px] tablet:pt-[6px]">
            Manage your clients and their sessions. View details and schedule
            appointments with ease.
          </p>
        </div>
        <hr className="w-full mt-7 border-none mb-7  h-[1px] bg-[#EAECF0]" />
        <div className="w-full  rounded-[16px] p-4 pb-6  tablet:p-5 min-h-[250px] bg-white">
          <div className="w-full flex flex-wrap gap-3 justify-end items-center">
            <div className="h-[36px] relative w-full sixt:w-[49%] sixt:max-w-[396px]">
              <img
                  src={searchIcon}
                  alt="search"
                  className="w-5 absolute top-1/2 left-2 transform -translate-y-1/2"
              />
              <input
                  style={{
                    outline: "none",
                    border: "1px solid #E2E4E9",
                    boxShadow: "none",
                  }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  type="search"
                  placeholder="Search"
                  className="size-full text-sm  rounded-[8px] py-2 pl-9 pr-8"
              />
            </div>
          </div>
          {isLoading ? (
              <div className="py-4">
                <Loader />
              </div>
          ) : !clientData.length ? (
              <div className="w-full flex-col my-auto laptop:min-h-[292px] mt-6 flex justify-center items-center">
                <p className="mt-3 text-sm text-[#868C98] text-center">
                  No Clients Found.
                </p>
              </div>
          ) : (
              <>
                <div className="overflow-x-scroll w-full">
                  <div className="mt-6 min-w-[1000px]">
                    <ClientTable
                        currentPageData={currentPageData}
                        handleTableSort={handleTableSort}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <PaginationComponent
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      setItemsPerPage={setItemsPerPage}
                      handlePageClick={handlePageClick}
                      tabLength={totalPages}
                  />
                </div>
              </>
          )}
        </div>
      </div>
  );
}

export default ClientList;
