import React from 'react'
import {useLocation, useParams, Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import backIcon from "../Images/back-chevron.png";
import gradient from "../Images/gradient.png";
import pass from "../Images/pass.png";
import avatar from "../Images/profile-avatar.png";
import {AnimatePresence} from "framer-motion";
import Overview from "../components/ClientProfile/Overview";
import Appointments from "../components/ClientProfile/Appointments";
import Worksheets from "../components/ClientProfile/Worksheets";
import {useGetClientDetailsQuery} from "../services/clientApiSlice";
import moment from "moment";
import ProfileNewSession from "../components/modals/NewSessions/profileNewSession";
import NewSessionWrapper from "@/App/components/modals/NewSessions/NewSessionWrapper.jsx";

function ClientProfile() {

    const navigate = useNavigate();
    const {id} = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const [newSession, setNewSession] = useState(false);

    const {data: details, isSuccess} = useGetClientDetailsQuery(id);

    const handleTabChange = (index) => {
        setActiveTab(index);
    };
    return (
        <div>
            <ProfileNewSession
                openModal={newSession}
                setModal={setNewSession}
                client={details?.data}
            />

            <NewSessionWrapper
                openModal={newSession}
                setModal={setNewSession}
                data={true}
            />

            <div className="w-full flex justify-start">
                <Link to="/dashboard/client_list">
                    <button className="flex bg-[#20232D] items-center justify-center gap-1 rounded-[50px] text-white py-[10px] pl-2 pr-4">
                        <img src={backIcon} className="w-5" alt="back"/>
                        <p className="text-sm font-semibold ">Back</p>
                    </button>
                </Link>
            </div>
            <div className="mt-9 flex flex-col justify-center items-start gap-6 laptop:flex-row laptop:min-h-[500px] laptop:gap-[3%]">
                <div className="bg-white  border rounded-[16px] max-w-[500px] laptop:min-h-[478px] tablet:max-w-full laptop:w-[28%] border-[#E2E4E9] pb-6 tablet:pb-7 laptop:pb-7 h-fit w-full">
                    <div className="h-[142px] relative  w-full ">
                        <div className="h-[50%] rounded-tr-[16px] overflow-hidden bg-transparent rounded-tl-[16px] ">
                            <img src={gradient} className="w-full" alt="fg"/>
                        </div>
                        {
                            isSuccess ?
                                <img
                                    style={{ backgroundColor: `${details?.data?.user?.avatar_background_color}` }}
                                    src={avatar || details?.data?.user?.avatar}
                                    alt="avatar"
                                    className="w-[87px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full"
                                />
                                :
                                null
                        }
                    </div>
                    <div className="w-full text-center">
                        <div className="flex justify-center items-center gap-3 mt-[-15px] w-full">
                            <h2 className="text-2xl font-bold capitalize text-[#0A0D14]">
                                {details?.data?.user?.name ?? '--'}
                            </h2>
                            <span className="size-[10px] bg-[#38C793] rounded-[100%]"></span>
                        </div>
                        <p className="font-medium text-base text-[#525866] pt-1">{details?.data?.user?.birth_year ? moment().diff(moment(`${details?.data?.user?.birth_year + '-01-01'}`, "YYYYMMDD"), 'years') : '--'}</p>
                        <div className="flex justify-center items-center gap-3 mt-4">
                            <button
                                onClick={() => setNewSession(true)}
                                className="rounded-[50px] bg-[#1A6A73] text-sm text-white py-[10px] font-semibold px-4"
                            >
                                Book Session
                            </button>
                            <button onClick={() => navigate("/dashboard/messages")} className="rounded-[50px] border border-[#E2E4E9] text-[#525866] text-sm  py-[10px] font-semibold px-4">
                                Send Message
                            </button>
                        </div>
                    </div>
                    <div
                        className="mt-6 w-[86%] laptop:w-[86%] tablet:w-full px-0 laptop:px-0 tablet:px-6 flex flex-col items-center tablet:gap-[3%] laptop:flex-col justify-center tablet:flex-row mx-auto space-y-4 tablet:space-y-0 laptop:space-y-4">
                        <div className="w-full tablet:w-[48%] laptop:w-full rounded-[8px] py-3 px-3 bg-[#F6F8FA]">
                            <div className="flex justify-between items-center gap-1">
                                <div className="flex justify-center items-center gap-1">
                                    <img src={pass} className="w-5" alt="check"/>
                                    <p className="leading-[18px] text-[13px] text-[#868C98]">
                                        Next session
                                    </p>
                                </div>
                                <p className="text-[12px] leading-4 font-medium text-[#693D11] bg-[#FBDFB1] rounded-[50px] px-2 py-[2px]">
                                    {details?.data?.next_session?.status}
                                </p>
                            </div>
                            <h3 className="text-[15px] leading-6 font-medium pt-[5px] pl-[23px] text-[#0A0D14]">
                                {details?.data?.next_session ? moment(details?.data?.next_session?.starts_at).format("MMMM Do YYYY") : '--'}
                            </h3>
                        </div>
                        <div className="w-full tablet:w-[48%] laptop:w-full  rounded-[8px] py-3 px-3 bg-[#F6F8FA]">
                            <div className="flex justify-between items-center gap-1">
                                <div className="flex justify-center items-center gap-1">
                                    <img src={pass} className="w-5" alt="check"/>
                                    <p className="leading-[18px] text-[13px] text-[#868C98]">
                                        Last session
                                    </p>
                                </div>
                                <p className="text-[12px] leading-4 font-medium text-[#176448] bg-[#CBF5E5] rounded-[50px] px-2 py-[2px]">
                                    {details?.data?.last_session?.status}
                                </p>
                            </div>
                            <h3 className="text-[15px] leading-6 font-medium pt-[5px] pl-[23px] text-[#0A0D14]">
                                {moment(details?.data?.last_session?.starts_at).format("MMMM Do YYYY") ?? '--'}
                            </h3>
                        </div>
                    </div>
                </div>
                {/* client Overview */}
                <div className="w-full bg-transparent  laptop:w-[69%] ">
                    <div className="bg-white w-full rounded-[10px] p-[10px] overflow-hidden">
                        <div className="h-[52px] z-[-2] bg-[#F6F8FA] w-full p-2 rounded-[10px] ">
                            <div className="flex *:font-medium  *:text-xs *:tablet:text-[15px] *:tablet:leading-[22px] items-center justify-center size-full relative">
                                <button
                                    onClick={() => handleTabChange(0)}
                                    className={`w-[33.33%] z-[2] ${
                                        activeTab === 0 ? "text-[#0A0D14]" : "text-[#868C98]"
                                    } text-center`}
                                >
                                    Overview
                                </button>
                                <button
                                    onClick={() => handleTabChange(1)}
                                    className={`w-[33.33%] z-[2] ${
                                        activeTab === 1 ? "text-[#0A0D14]" : "text-[#868C98]"
                                    } text-center`}
                                >
                                    Appointments
                                </button>
                                <button
                                    onClick={() => handleTabChange(2)}
                                    className={`w-[33.33%] z-[2] ${
                                        activeTab === 2 ? "text-[#0A0D14]" : "text-[#868C98]"
                                    } text-center`}
                                >
                                    Worksheets
                                </button>
                                <div
                                    className={`bg-white transition-all duration-500 ease-in-out z-[1] top-1/2 transform -translate-y-1/2 absolute rounded-[6px] `}
                                    style={{
                                        width: "33.33%",
                                        height: "calc(100%)",
                                        left: `calc(${activeTab * 33.33}%)`,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full mt-5">
                        <AnimatePresence mode="wait">
                            {activeTab === 0 ? (
                                <Overview details={details?.data}/>
                            ) : activeTab === 1 ? (
                                <Appointments/>
                            ) : activeTab === 2 ? (
                                <Worksheets/>
                            ) : null}
                        </AnimatePresence>
                    </div>
                </div>
                {/* end of Overview */}
            </div>
        </div>
    );
}

export default ClientProfile;
