import React from "react"
import plus from "../Images/plus.png";
import list from "../Images/list.png";
import prev from "../Images/cal-prev.png";
import next from "../Images/cal-next.png";

import listActive from "../Images/list-active.png";
import moreActive from "../Images/cal-active.png";
import searchIcon from "../Images/search.png";

import "../dashboard.css";
import { AnimatePresence } from "framer-motion";

import more from "../Images/upcoming.png";
import { useState, useMemo } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/Components/ui/select";
import Calendar from "../components/AppoinmentPage/Calendar";
import ListView from "../components/AppoinmentPage/ListView";
import NewSessionWrapper from "../components/modals/NewSessions/NewSessionWrapper";
import { Link } from "react-router-dom";
import { useGetAllSessionsQuery } from "../services/sessionSlice";
import moment from "moment";
import Loader from "@/Components/loader";

function Appointments() {
  const [calendarView, setCalendarView] = useState(true);
  const [newSession, setNewSession] = useState(false);
  const [search, setSearch] = useState("");
  const [sortCol, setSortCol] = useState("all");
  const [month, setMonth] = useState(new Date().getMonth()); // Current month
  const [year, setYear] = useState(new Date().getFullYear()); // Current year

  const { data:sessions, isLoading, isSuccess } = useGetAllSessionsQuery();
  // Log the sessions data to debug
  console.log("Sessions Data:", sessions);

  const transformedSessions = isSuccess && sessions?.data?.map((session) => {
    return {
      id: session.id,
      avatar: session?.user?.avatar,
      userId: session?.user?.id,
      name: session.user?.name,
      time: moment(session.starts_at).format("LT"),
      date: moment(session.starts_at).format("ddd MMM DD YYYY"),
      status: session.status,
      when: moment(session.starts_at).format("LT") === moment().format("LT"),
      startTime: session?.starts_at,
      duration: session?.duration
    }
  })
  console.log("Transformed Sessions:", transformedSessions);

  // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)

  const searchedData = 
    search.trim() !== ""
      ? transformedSessions.filter((item) => {
          if (item.name) {
            const nameArray = item.name.split(" ");
            const firstname = nameArray[0]?.toLowerCase().startsWith(search.toLowerCase());
            const lastname = nameArray?.[1]?.toLowerCase().startsWith(search.toLowerCase());
            const fullname = item.name?.toLowerCase().startsWith(search.toLowerCase());

            return firstname || lastname || fullname;
          }
        })
      : transformedSessions;
  
  // const [filteredData, setFilteredData] = useState(searchedData);

  let filteredData;
  if (sortCol === "all") {
    filteredData = searchedData;
  } else {
    filteredData = searchedData?.filter(
      (item) => item?.status?.toLowerCase() === sortCol?.toLowerCase()
    );
  }

  function handleFilter(value) {
    setSortCol(value);
  }

  // Function to generate calendar2 data
  const generateCalendarData = () => {
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const lastDayPrevMonth = new Date(year, month, 0).getDate();
    const calendarData = [];

    let day = 1;
    let prevMonthDays = lastDayPrevMonth - firstDay + 1;

    for (let i = 0; i < 6; i++) {
      const week = [];
      for (let j = 0; j < 7; j++) {
        const date = new Date(year, month, day);
        const dateString = dateToString(year, month, day);
        if (i === 0 && j < firstDay) {
          week.push({
            day: prevMonthDays,
            isPrevMonth: true,
            dateString,
            isSunday: date.getDay() === 0,
          });
          prevMonthDays++;
        } else if (day <= daysInMonth) {
          week.push({
            day,
            isPrevMonth: false,
            dateString,
            isSunday: date.getDay() === 0,
          });
          day++;
        } else {
          week.push({
            day: day - daysInMonth,
            isPrevMonth: true,
            dateString,
            isSunday: date.getDay() === 0,
          });
          day++;
        }
      }
      calendarData.push(week);
      if (day > daysInMonth) break;
    }

    return calendarData;
  };

  function dateToString(year, month, day) {
    const date = new Date(year, month, day);
    const dateString = date.toDateString();
    // console.log(year, month, day);

    return dateString;
  }

  function getTodaysDate() {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();

    // console.log(year, month, day);

    return dateToString(year, month, day);
  }

  function Today() {
    setMonth(new Date().getMonth());
    setYear(new Date().getFullYear());
  }

  const calendarData = generateCalendarData();
  const today = getTodaysDate();


  // Function to handle previous month
  const prevMonth = () => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  // Function to handle next month
  const nextMonth = () => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  return (
    <div>
      <NewSessionWrapper openModal={newSession} setModal={setNewSession} />
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-col tablet:flex-row justify-center w-full gap-3  tablet:justify-between items-center tablet:items-start">
          <div className="flex tablet:w-[50%] laptop:w-fit  laptop:max-w-[70%] flex-col justify-center items-center tablet:justify-start tablet:items-start">
            <h2 className="text-[#101828] text-left text-[1.4rem] leading-[30px] tablet:text-[1.9rem] tablet:leading-[38px] font-semibold">
              Appointments
            </h2>
            <p className="font-normal hidden tablet:inline-block  text-[1rem] text-left leading-6   text-[#475467] pt-2">
              View and manage your sessions, track performance metrics, and
              access resources to support your practice.
            </p>
          </div>
          <div className="flex justify-center items-center gap-3">
            <Link to="/dashboard/availability/availability_hours">
              <button className=" text-[13px] leading-[19px] tablet:text-sm text-white rounded-[50px] font-medium bg-[#20232D] py-[9px] tablet:py-[10px] px-4 tablet:px-5">
                Manage Availability
              </button>
            </Link>
            <button
              onClick={() => setNewSession(true)}
              className=" text-[13px] leading-[19px] tablet:text-sm text-white rounded-[50px] font-medium bg-[#1A6A73] py-[9px] tablet:py-[10px] pl-3 pr-4 tablet:pl-4 tablet:pr-5 flex justify-center gap-1 items-center"
            >
              <img className="w-5" src={plus} alt="add" /> New Session
            </button>
          </div>
        </div>
        <p className="font-normal inline-block tablet:hidden text-[14px] leading-[22px] tablet:text-[1rem] text-center tablet:text-left tablet:leading-6 max-w-[450px] laptop:max-w-full text-[#475467] pt-3 tablet:pt-2">
          View and manage your sessions, track performance metrics, and access
          resources to support your practice.
        </p>
      </div>
      <hr className="w-full my-7 border-none h-[1px] bg-[#EAECF0]" />
      <div className="rounded-[16px] p-4 bg-white border border-[#E2E4E9]">
        <div className="flex justify-start gap-[4%] tablet:gap-6 items-center *:flex *:gap-1 *:justify-center *:tablet:justify-start *:items-center *:text-sm *:font-medium *:cursor-pointer *:w-[48%] *:tablet:w-fit ">
          <div onClick={() => setCalendarView(true)}>
            <img
              className="w-5"
              src={calendarView ? moreActive : more}
              alt="grid view"
            />
            <p
              className={`${
                calendarView ? "text-[#0A0D14]" : "text-[#525866]"
              }`}
            >
              Calendar View
            </p>
          </div>
          <div onClick={() => setCalendarView(false)}>
            <img
              className="w-5"
              src={calendarView ? list : listActive}
              alt="grid view"
            />
            <p
              className={`${
                !calendarView ? "text-[#0A0D14]" : "text-[#525866]"
              }`}
            >
              List View
            </p>
          </div>
        </div>
        <div className="w-full hidden tablet:inline-block my-3 bg-[#E2E4E9] h-[2px]">
          <div
            className={`size-full transition-all duration-500 ease-in-out bg-[#1A6A73] ${
              calendarView ? "w-[122px]" : "ml-[144px] w-[85px]"
            }`}
          ></div>
        </div>
        <div className="w-full inline-block tablet:hidden my-3 bg-[#E2E4E9] h-[2px]">
          <div
            className={`size-full transition-all w-[50%] duration-500 ease-in-out bg-[#1A6A73] ${
              calendarView ? "" : "ml-[50%] "
            }`}
          ></div>
        </div>
        <div className="flex flex-col gap-3 tablet:flex-row justify-between items-center">
          <div className="flex justify-start w-full tablet:w-[50%] items-center gap-3">
            <button
              onClick={Today}
              className="bg-[#20232D] text-center hidden tablet:inline py-[10px] px-4 font-medium text-sm rounded-[50px] text-white"
            >
              Today
            </button>
            <div className="flex w-full tablet:w-fit justify-center items-center gap-2">
              <button onClick={prevMonth}>
                <img className="w-10" src={prev} alt="previous" />
              </button>
              <p className="text-sm text-center w-[118px] text-[#0A0D14] font-semibold">
                {new Date(year, month).toLocaleString("default", {
                  month: "long",
                })}
                {"   "}
                {year}
              </p>
              <button onClick={nextMonth}>
                <img className="w-10" src={next} alt="next" />
              </button>
            </div>
          </div>

          <div className="w-full tablet:w-[50%] flex flex-wrap gap-3 sixt:gap-[2%] tablet:gap-3 justify-end items-center">
            <div className="h-[36px] relative w-full sixt:w-[48%] tablet:w-[49%]  sixt:max-w-[396px] laptop:min-w-[396px]">
              <img
                src={searchIcon}
                alt="search"
                className="w-5 absolute top-1/2 left-2 transform -translate-y-1/2"
              />
              <input
                style={{
                  outline: "none",
                  border: "1px solid #E2E4E9",
                  boxShadow: "none",
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Search"
                className="size-full text-sm  rounded-[8px] py-2 pl-9 pr-8"
              />
            </div>
            <div className="flex w-full sixt:w-[50%] tablet:w-fit justify-between items-center gap-[4%] sixt:gap-3">
              <button
                onClick={Today}
                className="bg-[#20232D] w-[48%] sixt:w-[48%] text-center inline tablet:hidden py-[10px] px-4 font-medium text-sm rounded-[50px] text-white"
              >
                Today
              </button>
              <Select
                value={sortCol}
                defaultValue="all"
                onValueChange={(value) => handleFilter(value)}
              >
                <SelectTrigger className=" w-[48%] sixt:w-[48%] tablet:w-[135px] rounded-[50px] border-[#E2E4E9] focus:ring-offset-0 focus:ring-0">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="all">Show All</SelectItem>
                    <SelectItem value="upcoming">Upcoming</SelectItem>
                    <SelectItem value="completed">Completed</SelectItem>
                    <SelectItem value="confirmed">Confirmed</SelectItem>
                    <SelectItem value="cancelled">Cancelled</SelectItem>
                    <SelectItem value="not_started">Not Started</SelectItem>
                    <SelectItem value="no_show">No show</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 min-h-[50vh]">
        <AnimatePresence mode="wait">
          {calendarView ? (
            !isLoading ? 
              <Calendar
                calendarData={calendarData}
                filteredData={filteredData ?? []}
                dateToString={dateToString}
                month={month}
                year={year}
                today={today}
              />
              :
              <Loader />
          ) : (
            !isLoading ? <ListView filteredData={filteredData ?? []} month={month} year={year} /> : <Loader />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default Appointments;
