import React from "react"
import { motion } from "framer-motion";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/Components/ui/select";
import active from "../../Images/active_setting.png";
import complete from "../../Images/complete.png";
import upcom from "../../Images/upcoming.png";
import { useState } from "react";
import Upcoming from "../Appointments/Upcoming";
import Completed from "../Appointments/Completed";
import Cancelled from "../Appointments/Cancelled";
import NoShow from "../Appointments/NoShow";
import { useGetClientSessionsQuery } from "@/App/services/clientApiSlice";
import { useParams } from "react-router-dom";
import Confirmed from "../Appointments/confirmed";
import NotStarted from "../Appointments/NotStarted";

const variants = {
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: "easeOut",
      duration: 0.5,
    },
  },
};

function Appointments() {

  const { id } = useParams()
  const [activeTab, setActiveTab] = useState("upcoming");

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  return (
    <motion.div
      key="hello"
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
      className=" bg-white border laptop:min-h-[386px] rounded-[16px] border-[#E2E4E9] px-4 tablet:px-5 py-6 w-full"
    >
      <h3 className="text-[#0A0D14] mb-5 text-base font-semibold">
        Appointments
      </h3>
      <Select
        onValueChange={(value) => handleTabChange(value)}
        defaultValue={activeTab}
        value={activeTab}
      >
        <SelectTrigger className=" mb-3 px-[10px] nine:hidden w-[230px] focus:ring-offset-0 focus:ring-0 h-[45px]  font-semibold text-[#525866] ">
          <SelectValue />
        </SelectTrigger>
        <SelectContent className=" ">
          <SelectGroup className=" *:font-medium *:py-3 ">
            <SelectItem value="upcoming">
              <div className="flex justify-between w-full group ">
                <div className="flex justify-start items-center gap-2 ">
                  <img src={upcom} alt="personal" className="w-5" />
                  <p className="text-[#525866] text-[14px] leading-5 capitalize  font-medium">
                    upcoming
                  </p>
                </div>
              </div>
            </SelectItem>

            <SelectItem value="completed">
              <div className="flex justify-between w-full group ">
                <div className="flex justify-start items-center gap-2 ">
                  <img src={complete} alt="personal" className="w-5" />
                  <p className="text-[#525866] text-[14px] leading-5 capitalize  font-medium">
                    Completed
                  </p>
                </div>
              </div>
            </SelectItem>
            <SelectItem value="cancelled">
              <div className="flex justify-between w-full group ">
                <div className="flex justify-start items-center gap-2 ">
                  <img src={complete} alt="personal" className="w-5" />
                  <p className="text-[#525866] text-[14px] leading-5 capitalize  font-medium">
                    Cancelled
                  </p>
                </div>
              </div>
            </SelectItem>
            <SelectItem value="no show">
              <div className="flex justify-between w-full group ">
                <div className="flex justify-start items-center gap-2 ">
                  <img src={complete} alt="personal" className="w-5" />
                  <p className="text-[#525866] text-[14px] leading-5 capitalize  font-medium">
                    no show
                  </p>
                </div>
              </div>
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      <div className="flex justify-start items-start w-full  gap-[3%]">
        <div className="w-[27%] laptop:w-[24%]  bg-white px-[10px] pt-[10px] pb-3 rounded-[16px] border hidden nine:flex flex-col justify-start items-start border-[#E2E4E9]">
          <h2 className="text-[#868C98] px-2 pt-2 font-medium text-[12px] leading-4 uppercase">
            select menu
          </h2>
          <div className="mt-3 w-full space-y-2">
            <div
              onClick={() => handleTabChange("upcoming")}
              to="personal_info"
              className={`flex justify-between w-full rounded-[8px] group p-2 cursor-pointer ${
                activeTab === "upcoming" ? "is-active bg-[#F6F8FA]" : ""
              }`}
            >
              <div className="flex justify-start items-center gap-2 ">
                <img src={upcom} alt="personal" className="w-5" />
                <p className="text-[#525866] text-[14px] leading-5 capitalize group-[.is-active]:text-[#0A0D14] font-medium">
                  upcoming
                </p>
              </div>
              <img
                src={active}
                alt="chevron"
                className="w-5 hidden group-[.is-active]:block"
              />
            </div>
            <div
              onClick={() => handleTabChange("completed")}
              to="professional_info"
              className={`flex justify-between w-full rounded-[8px] group p-2 cursor-pointer ${
                activeTab === "completed" ? "is-active bg-[#F6F8FA]" : ""
              }`}
            >
              <div className="flex justify-start items-center gap-2 ">
                <img src={complete} alt="personal" className="w-5" />
                <p className="text-[#525866] text-[14px] leading-5 capitalize group-[.is-active]:text-[#0A0D14] font-medium">
                  Completed
                </p>
              </div>
              <img
                src={active}
                alt="chevron"
                className="w-5 hidden group-[.is-active]:block"
              />
            </div>
            <div
              onClick={() => handleTabChange("cancelled")}
              className={`flex justify-between w-full rounded-[8px] group p-2 cursor-pointer ${
                activeTab === "cancelled" ? "is-active bg-[#F6F8FA]" : ""
              }`}
            >
              <div className="flex justify-start items-center gap-2 ">
                <img src={complete} alt="personal" className="w-5" />
                <p className="text-[#525866] text-[14px] leading-5 capitalize group-[.is-active]:text-[#0A0D14] font-medium">
                  cancelled
                </p>
              </div>
              <img
                src={active}
                alt="chevron"
                className="w-5 hidden group-[.is-active]:block"
              />
            </div>

            <div
              onClick={() => handleTabChange("confirmed")}
              to="personal_info"
              className={`flex justify-between w-full rounded-[8px] group p-2 cursor-pointer ${
                activeTab === "confirmed" ? "is-active bg-[#F6F8FA]" : ""
              }`}
            >
              <div className="flex justify-start items-center gap-2 ">
                <img src={upcom} alt="personal" className="w-5" />
                <p className="text-[#525866] text-[14px] leading-5 capitalize group-[.is-active]:text-[#0A0D14] font-medium">
                  confirmed
                </p>
              </div>
              <img
                src={active}
                alt="chevron"
                className="w-5 hidden group-[.is-active]:block"
              />
            </div>

            <div
              onClick={() => handleTabChange("not started")}
              to="personal_info"
              className={`flex justify-between w-full rounded-[8px] group p-2 cursor-pointer ${
                activeTab === "not started" ? "is-active bg-[#F6F8FA]" : ""
              }`}
            >
              <div className="flex justify-start items-center gap-2 ">
                <img src={upcom} alt="personal" className="w-5" />
                <p className="text-[#525866] text-[14px] leading-5 capitalize group-[.is-active]:text-[#0A0D14] font-medium">
                  Not Started
                </p>
              </div>
              <img
                src={active}
                alt="chevron"
                className="w-5 hidden group-[.is-active]:block"
              />
            </div>

            <div
              onClick={() => handleTabChange("no show")}
              to="personal_info"
              className={`flex justify-between w-full rounded-[8px] group p-2 cursor-pointer ${
                activeTab === "no show" ? "is-active bg-[#F6F8FA]" : ""
              }`}
            >
              <div className="flex justify-start items-center gap-2 ">
                <img src={upcom} alt="personal" className="w-5" />
                <p className="text-[#525866] text-[14px] leading-5 capitalize group-[.is-active]:text-[#0A0D14] font-medium">
                  No Show
                </p>
              </div>
              <img
                src={active}
                alt="chevron"
                className="w-5 hidden group-[.is-active]:block"
              />
            </div>
          </div>
        </div>
        <div
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
          className="w-full nine:w-[70%] laptop:w-[73%] nine:max-h-[500px] overflow-y-scroll  laptop:min-h-full  h-fit"
        >
          {activeTab === "upcoming" ? (
            <Upcoming id={id} />
          ) : activeTab === "completed" ? (
            <Completed id={id} />
          ) : activeTab === "cancelled" ? (
            <Cancelled id={id} />
          ) : activeTab === "confirmed" ? (
            <Confirmed id={id} />
          ): activeTab === "not started" ? (
            <NotStarted id={id} />
          ): activeTab === "no show" ? (
            <NoShow id={id} />
          ): null}
        </div>
      </div>
    </motion.div>
  );
}

export default Appointments;
