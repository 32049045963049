import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/Components/ui/dialog";
import calendar from "../../../Images/calendar.png";
import pencil from "../../../Images/pencil.png";
import alarm from "../../../Images/alarm.png";
import TimeIcon from "../../../Images/time.png";
import avatar from "../../../Images/summary-avatar.png";
import { FormLoader } from "@/Components/loader";
import moment from "moment";
function ConfirmReschedule({ setModal, isLoading, handleSubmit, setConfirm, session, date, duration, time }) {
  
  function handleApprove() {
    // setConfirm(false);
    // setPage(1);
    setModal(false);
  }
  function handleEdit() {
    setConfirm(false);
  }
  return (
   
      <DialogContent
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        className="w-[90vw] overflow-y-scroll min-h-[ max-h-[80vh] max-w-[566px] py-6 duo:py-8 px-6 duo:pl-9 duo:pr-8 border-none rounded-[16px]"
      >
        <DialogHeader>
        
          <DialogTitle className="text-left text-[#0A0D14]">
                Confirm Rescheduling
              </DialogTitle>
              <DialogDescription className="text-[#525866] text-left">
                Review the session details before confirming.
              </DialogDescription>
        </DialogHeader>
        <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mt-2 mb-2" />
        <div className="">
          <div className="flex flex-col justify-center items-center">
            <img src={session?.data?.user?.avatar} alt="avatar" className="w-[85px]" />
            <h2 className="text-2xl font-bold capitalize pt-3 text-[#0A0D14]">
              {session?.data?.user?.name}
            </h2>
            <p className="text-sm pt-1 text-[#525866]">8 previous sessions</p>
          </div>
          <div className="bg-[#F6F8FA] w-full rounded-[8px] py-[10px] px-4 mt-5 space-y-5 ">
            <div>
              <h4 className="text-xs text-[#525866]">Date</h4>
              <div className="flex justify-start items-start gap-[6px] mt-2">
                <img className="w-5" src={calendar} alt="date" />
                <h2 className="text-sm text-[#0A0D14]">
                  {moment(date).format("dddd,  MMMM Do YYYY")}
                </h2>
              </div>
            </div>
            <div>
              <h4 className="text-xs text-[#525866]">Time</h4>
              <div className="flex justify-start items-start gap-[6px] mt-2">
                <img className="w-5" src={TimeIcon} alt="time" />
                <h2 className="text-sm text-[#0A0D14]">{time}</h2>
              </div>
            </div>
            <div>
              <h4 className="text-xs text-[#525866]">Duration</h4>
              <div className="flex justify-start items-start gap-[6px] mt-2">
                <img className="w-5" src={alarm} alt="duration" />
                <h2 className="text-sm text-[#0A0D14]">{duration} mins</h2>
              </div>
            </div>
            <div>
              <h4 className="text-xs text-[#525866]">Additional Note</h4>
              <div className="flex justify-start items-start gap-[6px] mt-2">
                <img className="w-5" src={pencil} alt="date" />
                <h2 className="text-sm text-[#0A0D14]">
                  {session?.data?.note ?? '---'}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mt-2 mb-2" />
        <div className="w-full flex justify-end gap-2 items-center">
          <button
            onClick={handleEdit}
            className="bg-transparent border border-[#E2E4E9] font-meduim text-[#1A6A73] text-sm rounded-[50px] py-[10px] px-4 "
          >
            Edit
          </button>
          <button
            onClick={handleSubmit}
            className="text-white font-meduim bg-[#1A6A73] text-sm rounded-[50px] py-[10px] px-4 "
          >
            {isLoading ? <FormLoader /> : 'Confirm Reschedule'}
          </button>
        </div>
      </DialogContent>
    
  );
}

export default ConfirmReschedule;
