import React, { useState } from "react";
import VideoScreen from "./VideoScreen";
import { WaitingRoom } from "./WaitingRoom";
import { useLocation } from "react-router-dom";

const VideoChat = () => {

    // let iceCandidates = [], room, caller;
    const { state: client } = useLocation();
    const [isActiveCall, setIsActiveCall] = useState(false);
    
    console.log('isActiveCall: ', isActiveCall)

    return (
        <div>
            { !isActiveCall ? 
                <WaitingRoom
                    setIsActiveCall={setIsActiveCall}
                    text={""}
                    client={client}
                /> 
                : 
                null 
            }
            { isActiveCall ? 
                <VideoScreen
                    isActiveCall={isActiveCall}
                    // iceCandidates={iceCandidates}
                    // room={room}
                    // caller={caller}
                    client={client}
                /> 
                : 
                null 
            }
        </div>
    )
}

export default VideoChat

